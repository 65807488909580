import React, { useState, useEffect, } from 'react';
import Pagination from './Pagination';
import { getLayouts, CATEGORIES } from './helpers';
import Grid from './Grid';
import BackDrop from './BackDrop';
import Chip from '@material-ui/core/Chip';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f4511e',
    },
    secondary: {
      main: '#dd2c00',
    },
  },

});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: 30,
    marginTop: 30,
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));



function App() {
  const [layouts, setLayouts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([])
  const [filterBy, setFilterBy] = useState(null);
  // this method is to get all categories and then fetch the layouts.
  const getCategories = () => {
    fetch('https://templatesapi.drummerboyhosting.com/wp-json/wp/v2/categories?per_page=100')
      .then(data => data.json())
      .then(data => {
        data.unshift({ id: 'all', name: 'All' })
        const finalData = data.filter((cat) => cat.name.toLowerCase() !== 'uncategorized')
        setCategories(finalData)
      })
      .then(() => getLayouts(1, 12, setLayouts, setIsLoading, null, ''))
      .catch(e => console.error('an error occurred', e));
  }
  // we init all categories call on first load.
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, [])

  const handlePageChange = (event, value) => {
    const cat = filterBy ? filterBy.category : null;
    const url = filterBy ? CATEGORIES : '';
    getLayouts(value, 12, setLayouts, setIsLoading, cat, url);
  }

  const filterData = (id) => {
    if (id !== 'all') {
      setFilterBy({
        category: id,
        page: 1,
      })
    } else {
      setFilterBy(null);
    }
    const cat = id !== 'all' ? id : null;
    const url = id !== 'all' ? CATEGORIES : '';
    getLayouts(1, 12, setLayouts, setIsLoading, cat, url);

  }

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div className={classes.root}>
          {categories.length ? categories.map((cat) => <Chip key={cat.id}
            label={cat.name}
            onClick={() => filterData(cat.id)}
          />) : null}
        </div>
        <BackDrop status={isLoading} />
        <Grid layouts={layouts.data} />
        <Pagination handleChange={handlePageChange} count={layouts.totalPages} page={layouts.currentPage || 1} />
      </div >
    </ThemeProvider>
  );
}

export default App;
