import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(.99),
        textAlign: 'center',
        color: theme.palette.text.primary.main,
        border: `1px solid rgb(224 224 224)`,
        background: theme.palette.background.primary,
        overflow: 'auto',
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
    },
    iconButton: {
        float: 'right',
        fontSize: '0.59rem',
        color: theme.palette.primary,
        marginRight: 10,
    },
    buttonsContainer: {
        width: '100%',
        float: 'left',
        marginBottom: 7,
    },
}));


const RenderItem = ({ title, image, preview, download }) => {
    const classes = useStyles();

    return <Grid item xs={12} sm={4}>
        <Paper elevation={0} className={classes.paper}>
            <Link href={preview} target="_blank" rel="noopener">
                <img className={classes.image} src={image} alt="" />
            </Link>
            <h3 style={{ margin: '20px 0', fontWeight: 'normal', fontSize: 12 }}>{title}</h3>

            <div className={classes.buttonsContainer}>
                <IconButton href={download} style={{ marginRight: 0, }} target="_blank" alt="Download this layout" className={classes.iconButton} size="small" variant="contained" color="secondary">
                    <GetAppRoundedIcon />
                </IconButton>
                <IconButton href={preview} target="_blank" alt="Preview this layout" className={classes.iconButton} size="small" variant="contained" >
                    <VisibilityRoundedIcon variant="small" />
                </IconButton>
            </div>
        </Paper>
    </Grid>
}

const CenteredGrid = ({ layouts }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {layouts && layouts.map && layouts.map(item =>
                    <RenderItem
                        key={item.id}
                        image={item._embedded["wp:featuredmedia"][0].source_url}
                        title={`Template id: ${item.title.rendered}`}
                        preview={item.meta.preview}
                        download={item.meta.download} />)}
            </Grid>
        </div>
    );
}

RenderItem.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,
    preview: PropTypes.string,
    download: PropTypes.string,
}

export default CenteredGrid