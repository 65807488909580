import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function PaginationSize({ handleChange, page, count }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {/* <Pagination count={pages} size="small" onChange={(e) => handleChange(e, null, page + 1)} /> */}
            <Pagination count={count} page={page} onChange={handleChange} />
        </div>
    );
}