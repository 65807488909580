export const CATEGORIES = 'categories';
export const rootDomain = 'https://templatesapi.drummerboyhosting.com/wp-json/wp/v2/layout';
export const getLayouts = async (page, limit, callback, setIsLoading, categoryIds, url) => {
    if (setIsLoading) {
        setIsLoading(true);
    }
    const all = `${rootDomain}?_embed&page=${page}&per_page=${limit}`;
    const withCategories = `${rootDomain}?_embed&page=${page}&per_page=${limit}&categories=${categoryIds}`;
    const endpoint = url === CATEGORIES ? withCategories : all;

    const results = fetch(endpoint)
        .then(async (res) => {
            const data = await res.json();
            const totalPages = await parseInt(res.headers.get('X-WP-TotalPages'));
            const totalPosts = await parseInt(res.headers.get('X-WP-Total'));
            const final = {
                data,
                originalData: data,
                totalPages,
                totalPosts,
                currentPage: page,
            }
            if (setIsLoading) {
                setIsLoading(false);
            }
            return callback(final)
        })
        .catch((e) => e)
    return await results;
}